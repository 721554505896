
.subsub {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.subsub .tab-link {
  min-width: 110px;
}

.subsub .active-black {
  color: black;
  opacity: 1;
}

.subsub .mat-tab-links > .mat-tab-label-active {

  text-transform: uppercase;
  opacity: 1;
}

.subsub .style-upper {
  text-transform: uppercase;
}
