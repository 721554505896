
.upload-dashed-block {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'  rx='4' ry='4' stroke='%23e3e3e3' stroke-width='4' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 4px;
  background-color: white;
  width: 100%;
  height: 100%;
}

.upload-dashed-block.dragging{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none'  rx='4' ry='4' stroke='%23e3e3e3' stroke-width='4' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 4px;
  background-color: gainsboro;
}

.featured-image {
  //width: 418px;
  width: 100%;
  height: 230px;
}


.upload-text-color {
  color: #4D4F5C;
  opacity: 0.5;
}

.upload-text-color i.fas {
  font-size: 55px;
}

.font-regular-12px-22px-roboto {
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  font-family: Roboto;

}

.font-medium-22px-25px-roboto {
  font-weight: 500;
  font-size: 22px;
  line-height: 25px;
  font-family: Roboto;

}

.game-screenshot-outer {
  width:236px;
  height: 418px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

app-manage-game .game-icon {
  width: 68px;
  margin-right: 1rem;
}

app-manage-game  .game-icon img {
  width: 68px;
}

app-manage-game  .form-group {
  margin-bottom: 2rem;
}

//app-manage-game

app-manage-game .form-label {
  color: #0000008A;
  font-size: 14px;
  margin: 0 0 0.5rem;
}


app-portal-game-form-details .form-heading {
  margin: 0;
  font-weight: 400;
}

app-portal-game-form-details  .description {
  min-height: 120px;
}

app-manage-game .form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

app-portal-game-manage-featured  .form-drag-drop {
  margin-left: 5rem;
  width: 404px;
}

app-portal-game-manage-featured  .table-icon {
  font-size: 16px;
  height: unset;
  width: unset;
  vertical-align: text-top;
}

app-portal-game-manage-featured  .reviews {
  margin-left: 10px;
  color: #000000DE;
  opacity: 0.5;
}
