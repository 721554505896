
app-run-tile .tile .outer {
  position: relative;
  width: 100px;
  height: 100px;
}

app-run-tile .tile .oval {
  position: absolute;
  width: 100px;
  height: 100px;

  border-radius: 50px 50px 50px 50px;
  opacity: 0.07;
  background-color: #d3107f;
}

app-run-tile .tile .text-number {
  position: absolute;

  width: 100px;
  height: 100px;
  font-size: 35px;
  font-weight: 900;
  text-align: center;
  color: #d3107f;
}

app-run-tile .tile .text-text {
  position: absolute;
  font-weight: 900;
  color: #d3107f;
  width: 100px;
  text-align: center;
  bottom: -10px;

}
