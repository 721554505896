$headerHeight: 144px;
$column-width: 306px;
$bottom-toolbar-height: 75px;
$button-height: 43px;

@import 'generic';
@import 'header/index';
@import 'util/index';
@import 'util/manage_portal_games';
@import 'tables/index';
@import 'tabs/run_tab';
@import 'tabs/sub_navbar';
@import 'generalitem/index';
@import 'generalitem/multiplechoice';
@import 'generalitem/preview';
@import 'login/login-screen-theme';
@import 'portal-user-management';
@import 'responses/index';
@import 'wireflow/index';
@import 'game/theme';
@import 'tile/index';
@import 'runs/collaborators';
@import 'runs/settings';
@import 'modal/index';
@import 'button/index';
@import 'toolbar/index';

.my-snack-bar {
  background-color: #f44336;
}

.title-font-1 {
  text-align: center;
  font: 400 32px/43px Roboto;
  letter-spacing: 0;
  color: #D50380;
  opacity: 1;
  height: 56px;
}

.subtitle {
  text-align: center;
  font: 300 17px/13px Roboto;
  letter-spacing: 0;
  color: #000000;
  opacity: 0.3;
}


html,
body {
  height: 100%;
  margin: 0
}

body {
  background: $background-color;
}

