app-text-questions-gallery .load-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

app-text-questions-gallery .answer {
  box-shadow: none;
  border: 1px solid #EDEDED;
  border-radius: 0;
  padding: 12px 8px 12px 8px ;
  height: 122px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
}


app-text-questions-gallery .wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}

app-text-questions-gallery .icon-row {
  width: 23px;
}

app-text-questions-gallery .text-details-card {
  padding-left: 9px;
  overflow-y: auto;
}


app-text-questions-gallery .details-content {
margin-top: 18px;
}
