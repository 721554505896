//$smartphone-content-width: 320px;
//$smartphone-content-height: 640px;
//
//$smartphone-width: 392px;
//$smartphone-height: 760px;

$smartphone-content-width: 160px;
$smartphone-content-height: 320px;

$smartphone-content-width-new: 360px;
$smartphone-content-height-new: 640px;

$smartphone-width: 202px;
$smartphone-height: 380px;

//width:238px;
//height: 461px;


.preview-content-body {
  background-color: black;
  width: $smartphone-content-width-new;
  height: $smartphone-content-height-new;
}


.preview-content-body .mobile-button {
  color: white;
  height: 52px;
  border: 2px solid white;

  border-radius: 26px;
}

.preview-content-body .mobile-button-solid {
  min-height: 52px;
  border-radius: 26px;
  color:white;
}


.smartphone-new {
  border: 8px black solid;
  position: relative;
  border-top-width: 40px;
  border-bottom-width: 40px;
  border-radius: 18px;
}

.smartphone-new:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -19px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

.smartphone-new:after {
  content: '';
  display: block;
  width: 27px;
  height: 27px;
  position: absolute;
  left: 50%;
  bottom: -48px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

.smartphone {
  position: relative;

  width: $smartphone-width;
  height: $smartphone-height;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}


/* The horizontal line on the top of the device */
.preview-pane .smartphone:before {
  content: '';
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.preview-pane .smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.preview-pane .smartphone .content {
  width: $smartphone-content-width;
  height: $smartphone-content-height;
  background: white;
}

.preview-pane .smartphone-hov:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 25px #00000029;
}


.preview-pane.content {
  //width: 160px;
  //height: 320px;
  width: $smartphone-content-width;
  height: $smartphone-content-height;

  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.preview-pane .mobile-button {
  color: white;
  height: 52px;
  border: 2px solid white;

  border-radius: 26px;
}

.preview-pane .mobile-button-solid {
  height: 52px;
  border-radius: 26px;
  color:white;
}

.preview-pane .combination-outer {
  height: 165px;
}

.preview-pane  .top-description {
  padding: 12px;
  color: white;
}

.darken-background {
  color: white;
  background: rgba(0, 0, 0, 0.6);
}
.preview-pane .black-background {

  color: white;
  background: rgba(0, 0, 0, 1);
}


.preview-pane .full-with-height-container {
  height: $smartphone-content-height;
  width: 100%;
}

.text-box-preview {
  max-height: 400px;
background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 19px 38px #00000042;
  border-radius: 2px;
  opacity: 1;
  overflow-x: hidden;
}

.text-box-preview .heading {
  font-weight: bold;
  text-transform: uppercase;
}

.preview-outer-pane {
  position: relative;
  top: -24px;
  /*background-color: #7e003c;*/
  width: 100%;
}

.scalable  .preview-75{
  transform: scale(0.80) translate( -50%, -10.0%);
}


.scalable  .preview-half{
  transform: scale(0.5) translate( 0, -50%);
}


.zoom-form-field mat-form-field  {
  width: 70px;
}
