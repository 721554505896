

.game-theme-selector.width-only {
  width: 306px;
}

.game-theme-selector.box {
  width: $column-width;
  height: 246px;
}
