@import 'run-tile';
@import 'player-tile';
@import 'connection-tile';

.tile-dimensions {
  width: 236px;
  height: 388px;
}

.tile-dimensions.new-button-screen {
  border-radius: 2px;
  border: solid 1px #fff;
}

.bibendo-tile {
  width: 236px;
  height: 388px;
  margin: 8px;
}

.tile-style {
  background: #E4E9EB 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 2px;
}

.tile-style:hover {
  transform: translateY(-4px);
  box-shadow: 0px 6px 25px #00000029;
}

.tile .position-icon {
  position: absolute;
  top: 16px;
  left: 14px;
}

.tile .icon-style {
  color: #ffffff;
  font-size: 22px;
}

.tile .position-footer {
  position: absolute;
  height: 81px;
  width: 100%;
  bottom: 0px;
}

.tile .footer-style {
  background: #FFFFFF 0 0 no-repeat padding-box;
  border-radius: 0 0 2px 2px;
}

.tile .position-footer-title {
  position: absolute;
  top: 19px;
  height: 18px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}

.tile .footer-title-style {
  text-align: center;
  color: #000000DE;
  opacity: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tile .position-footer-subtitle {
  position: absolute;
  top: 42px;
  width: 100%;
}

.tile .footer-subtitle-style {
  text-align: center;
  color: #000000DE;
  opacity: 0.54;
}

