// game settings page - remove underline

.gl-editor  div.mat-form-field-underline {
    height: 0px;
}
.gl-editor .mat-select-value {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    font-family: Roboto;

    letter-spacing: 0px;
}

.gl-editor.mat-form-field {

    top:-13px;
    height: 32px;

    width: 100px;
}


//multiple choice answer option, put toggle right
.gl-multiple-choice .mat-slide-toggle-label {
    justify-content: space-between;
}
