app-responses-bottom-toolbar .toolbar-white {
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  height: 71px;
}
app-responses-bottom-toolbar .toolbar-white .maxwidth {
  flex: 1;
  display: flex;
}

app-responses-bottom-toolbar .screens {
  margin-left: 40px;
  width: 100%;
  overflow-x: auto;
}

@media all and (min-width: 992px) {
  app-responses-bottom-toolbar .screens {
    width: 70vw;
    margin-right: -50%;
  }
}

app-responses-bottom-toolbar .count-wrapper {
  display: flex;
  align-items: center;
}

app-responses-bottom-toolbar .count-label {
  margin-right: 10px;
  text-transform: uppercase;
}

app-responses-bottom-toolbar .count {
  min-height: 28px;
  padding: 5px 20px;
}

app-responses-bottom-toolbar .horizontal-scroll-wrapper::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

app-responses-bottom-toolbar .horizontal-scroll-wrapper::-webkit-scrollbar-button {
  width: 1px;
  height: 1px;
}

app-responses-bottom-toolbar .screens .mat-chip-list-wrapper {
  flex-wrap: nowrap;
  margin: 0;
}
