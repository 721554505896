

//. toolbar {
//
//}

.toolbar-wrapper {
  position: fixed;
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  background: #ffffff;
  z-index: 10;
  height: $bottom-toolbar-height;
}

.toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: $bottom-toolbar-height;
  background-color: #ffffff;
}

.toolbar .image {
  height: 64px;
  width: 42px;
  margin-right: 20px;
  min-height: $bottom-toolbar-height;
}
