//app-run-tab-select .tab-line {
//  display: flex;
//  flex-direction: row;
//  align-items: center;
//}
app-run-tab-select  .tab-link {
  min-width: 110px;
}

//app-run-tab-select .active-black {
//  color: black;
//  opacity: 1;
//}

//app-run-tab-select .mat-tab-links > .mat-tab-label-active {
//  text-transform: uppercase;
//  opacity: 1;
//}

app-run-tab-select .style-upper {
  text-transform: uppercase;
}

app-run-tab-select .search-block {
  margin-left: 20px;
}
app-run-tab-select .content {
  margin-left: auto;
  z-index: 100;
}
