
app-run-player-tile .style-fa-icon {
  margin-right: 16px;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.54);
  font-size: 19px;
}

app-run-player-tile .style-icon {
  position: absolute;
  top: 7px;
  right: 7px;
  transform: scale(0.83);
  cursor: pointer;
}

app-run-player-tile .avatar-circle {
  position: absolute;
  border-radius: 50%;
  top: 24px;
  left: 37px;
  right: 37px;
  width: 78px;
  height: 78px;
  background: #E4E9EB 0% 0% no-repeat padding-box;
  opacity: 1;
}

app-run-player-tile .avatar-text {
  position: absolute;
  border-radius: 50%;
  top: 49px;
  left: 37px;

  width: 78px;
  height: 28px;

  /*background: #E4E9EB 0% 0% no-repeat padding-box;*/
  opacity: 1;
  text-align: center;
  color: #000000;
  opacity: 0.3;
  text-transform: uppercase;
}

app-run-player-tile .avatar-br-annotation {
  position: absolute;
  border-radius: 50%;
  top: 75px;
  left: 94px;

  width: 20px;
  height: 20px;


  opacity: 1;
  text-align: center;
  color: #000000;

}

app-run-player-tile .name-section {
  position: absolute;
  top: 114px;
  padding-left: 5px;
  padding-right: 5px;
  width: 100%;
  text-align: center;
  letter-spacing: 0;
  color: #000000;
  opacity: 0.54;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

app-run-player-tile .remove-button {
  position: absolute;
  top: 133px;
  width: 100%;
  text-align: center;

}

app-run-player-tile .button-text {
  text-transform: uppercase;
}
