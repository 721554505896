
.content-padding-top-100 {
  padding-top: 100px;
}

.mb-1rem {
  margin-bottom: 1rem;
}

.mt-1rem {
  margin-top: 1rem;
}


.button-dimensions button{
  height: 50px;
  width: 150px;
}

.avatar-big-dimensions {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.forms-width-500 {
  min-width: 500px;
}

.actions-btn {
  margin: 0 20px;
  height: 44px;
}
