@import "icon";

.gi-edit-field-label {
  margin-top: 17px;
  margin-bottom: 15px;
  text-align: left;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: Roboto;

  letter-spacing: 0;
  //color: #0000008A;
  color: #888888;
  opacity: 1;
}

app-message-top-nav-bar .header .back-button {
  bottom: -93px;
}

app-run-top-nav-component .header .back-button {
  bottom: -93px;
}

app-pick-location-on-map .map-area{
  margin-top: 26px;
  width: 404px;
  height: 297px;
  background: transparent  0% 0% no-repeat padding-box;
  border: 4px solid #FFFFFF;
  opacity: 1;
}

app-pick-location-on-map .map {
  width: 100%;
  height: 100%;
  position: relative;
}


.edit-message-2-col-pane {
  margin-top: 57px;
  //background-color: yellow;
  width: 100%;

}

.edit-message-2-col-pane .preview {
  //background-color: blue;
  //height: 100px;

  min-width: $column-width;
  width: $column-width;
  //max-width: $column-width;
}

.edit-message-2-col-pane .form {
  //background-color: blue;

  width: $column-width;
  min-width: $column-width;
}
