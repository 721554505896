.header {
  top: 0px;
  right: 307px;
  width: 100%;
  height: $headerHeight;
  opacity: 1;
  z-index: 1;
}


.header select-language {
  position: absolute;
  top: 0px;
  right: 0px;

}

.header app-user-drop-down-display {
  position: absolute;
  top: 0px;
  right: 40px;
}

.header .account-dropdown {
  position: absolute;
  top: 3px;
  right: 0px;
  width: 250px;
  height: 50px;

  color: #FFFFFF;
  opacity: 0.7;
  z-index: 1;
}

.header .title {
  top: 55px;
  height: 38px;
  text-align: left;
  letter-spacing: 0;
  @include a700-color-mixin;
  position: absolute;
  z-index: 2;
}
.header   .date {

  top: 108px;
  height: 18px;
  text-align: left;
  font: 300 12px/20px Roboto;
  letter-spacing: 0;
  color: #FFFFFFDE;
  opacity: 0.7;
  position: absolute;
}

.header .back-button {
  position: absolute;
  cursor: pointer;
  bottom: -45px;
  left: -60px;
}

.header .context-tabs {
  position: absolute;
  top: 96px;
  text-transform: uppercase;
}

.header .root-tabs {
  text-transform: uppercase;
}

.header .root-tabs > .mat-tab-links > .mat-tab-label-active {
  color: #FFFFFF;

  opacity: 1;
}

.header  a.mat-tab-link.active-color {
  color: #FFFFFF;
  opacity: 1;
}

.header .mat-tab-links > .mat-tab-link {

  padding: 0 20px 0px 0px;
  justify-content: normal;

}

.header  .progress.mat-progress-bar {
  position: absolute;
}
