.connection-tile {
  position: relative;
  width: 152px;
  height: 160px;

  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 10px #0000001A;
  border-radius: 2px;
  opacity: 1;

  margin: 8px;
}

.connection-tile.pending {
  background: rgba(255, 255, 255, 0.54) 0% 0% no-repeat padding-box;
}

.connection-tile-dimensions {
  width: 152px;
  height: 160px;
}

.connection-tile-dimensions.new-button-screen {
  border-radius: 2px;
  border: solid 1px #fff;
}
