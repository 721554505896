
.run-settings-pane {
  //background-color: yellow;
  height: 300px;
  margin-top: 130px;
}


.run-settings-form {
  margin-left: 84px;
  //background-color: red;
  margin-top: 43px;
  min-width: 390px;
  height: 100%;
}


.run-settings-qr {
  padding: 33px;
  width: 225px;
  height: 300px;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
