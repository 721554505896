@import "toolbar";

.modal-fullscreen.modal-dialog {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  max-width: none !important;


}

.modal-fullscreen.modal-dialog > .mat-dialog-container {
  box-sizing: unset !important;
  border-radius: 0px !important;

}


.modal-fullscreen .gl-pos-modal-esc-button {
  right: 0;
}

.modal-fullscreen .gl-pos-modal-back-button {
  left: 0;
}


.modal-fullscreen .pos-top {
  height: 1px;
}

.modal-fullscreen  .pos-title {
  position: relative;
  margin-top: 83px;
  height: 38px;
  text-align: center;
}
