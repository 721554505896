.player-bg .mat-dialog-container {
  background-color: #ffffff !important;
}

.player {
  display: flex;
}

.player__video video {
  width: 360px;
  min-height: 470px;
}

.player__content {
  min-width: 200px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.player__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.player__arrows > * {
  margin-right: 10px;
}

.player__text {
  flex: 1;
}

.player__user {
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.player__user .user {
  padding: 0;
}

.player__user .user__avatar {
  margin-right: 10px;
}

.player__user .user__name {
  color: rgba(0, 0, 0, 0.54);
}

.video-item {
  position: relative;
  cursor: pointer;
}

.video-item .user-placeholder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 48px;
  display: none;
  align-items: flex-end;
}

.video-icon {
  position: absolute;
  top: 7px;
  right: 10px;
  color: #ffffff;
}

.video-item:hover .user-placeholder {
  display: flex;
}

.video-el {
  width: 100%;
}

.user {
  display: flex;
  align-items: center;
  padding: 6px;
}

.user__avatar {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  line-height: 31px;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.player__user .user__avatar {
  background-color: #DFE4E6;
}

.video-gallery {
  min-height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 8px;
}

.video-gallery__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}

.load-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
