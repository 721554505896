
//app-game-detail-collaborators {
//  display: block;
//  width: 100%;
//  margin-left: 84px;
//}

app-run-collaborators-page .pos-settings-container {
  position: relative;
  width: 487px;

  /*background-color: blue;*/
  display: flex;
  justify-content: space-between;

}


.collaborators-small {

         text-align: left;
         color: #0000008A;

     }
