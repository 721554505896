.fas.fa-waveform.audio-wave {
  font-size: 33px;
  color: white;
}
.audio-item {
  position: relative;
  height: 135px;
  background: #D9DFE2;
  padding: 4px;
}

.audio-item__title {
  font-size: 12px;
}

.audio-item .user-placeholder {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 48px;
  display: none;
  align-items: flex-end;
}

.audio-item:hover .user-placeholder {
  display: flex;
}

.user {
  display: flex;
  align-items: center;
  padding: 6px;
}

.user__avatar {
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 50%;
  line-height: 31px;
  font-size: 12px;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.audio-gallery {
  min-height: 100%;
  width: 100%;
  background-color: #ffffff;
  padding: 8px;
}

.audio-gallery__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}

.load-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

mat-basic-audio-player {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.audio-gallery .audio-item .ngx-basic-audio-player {
  min-width: 90px;
  box-shadow: none;
  background: #D9DFE2;
  border-radius: 0;
}

.audio-gallery .audio-item .ngx-basic-audio-player-button {
  padding: 0;
  min-width: 2px;
  opacity: 0.4;
}

.audio-gallery .disabled .audio-item:not(.playing) .ngx-basic-audio-player-button {
  pointer-events: none;
}

.audio-gallery .disabled .audio-item:not(.playing) .ngx-basic-audio-player {
  cursor: not-allowed;
}

.audio-gallery .audio-item .ngx-basic-audio-player-button:hover {
  opacity: 1;
}

.audio-gallery .audio-item .ngx-basic-audio-player .mat-slider-horizontal {
  min-width: 4px;
}

.audio-gallery .audio-item .ngx-basic-audio-player-duration {
  display: none !important;
}
