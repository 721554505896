@import './audio-player';
@import './responses-bottom-toolbar';
@import './text-questions';
@import './video-player';
@import './answers-gallery';


app-responses-overview  .photo-container {
  border-right: 1px solid #e8eaea;
  margin: 0 20px 0 0;
}

app-responses-overview  .photo {
  margin: 0 20px 0 0;
  min-height: 360px;
  position: relative;
}

app-responses-overview  .photo img {
  display: block;
  width: 100%;
  height: 100%;
}

app-responses-overview .photo  .preview-outer-pane {
  top: 0;
}

app-responses-overview .photo .preview-outer-pane .preview-pane {
  //width: 280px;
  width: 360px;
  height: 640px;
  background: white;
}

app-responses-overview  .answers-wrapper {
  width: 100%;
  margin: -1rem;
  padding: 1rem;
  overflow: auto;
}


.masonry {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(122px, 1fr));
  grid-auto-flow: row dense;
  grid-gap: 10px;
}

.masonry-brick {
  position: relative;
  aspect-ratio: 1 / 1;
}

.masonry-brick .user-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  align-items: flex-end;
  background: linear-gradient(0deg, rgba(49, 49, 49, 1) 0%, rgba(255, 255, 255, 0) 100%);
}



.photo-gallery {
  width: 100%;
  padding: 4px;
  background-color: #ffffff;
  height: 100%;
}

.load-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-bg .mat-dialog-container {
  background-color: #ffffff !important;

}

.player-bg-fullwidth.modal-dialog {
  width: 100% !important;
  //height: 100% !important;
  //margin: 0 !important;
  //padding: 0 !important;
  //max-width: none !important;


}

.player {
  display: flex;
}

.player__image div {
  min-width: 360px;
  aspect-ratio: 1 / 1;
  width: 100%;
}

.player__content {
  min-width: 200px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}

.player__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.player__arrows > * {
  margin-right: 10px;
}

.player__text {
  flex: 1;
}


.masonry-brick:hover .user-placeholder {
  display: flex;
}

.masonry-brick img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.user {
  display: flex;
  align-items: center;
  padding: 10px;
  overflow: hidden;
  width: 100%;
}

.user__avatar {
  height: 23px;
  width: 23px;
  min-width: 23px;
  background-color: #DFE4E6;
  border-radius: 50%;
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  margin-right: 6px;
  color: rgba(0, 0, 0, 0.6);
}

app-text-questions-gallery .user__avatar {
  border-radius: 2px;
}

.user__name {
  color: #ffffff;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}


.player__user {
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.player__user .user {
  padding: 0;
}

.player__user .user__avatar {
  margin-right: 10px;
}

.player__user .user__name {
  color: rgba(0, 0, 0, 0.54);
}
