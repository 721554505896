.login-screen.pos-full-canvas {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

  .login-screen .style-image-left {
    background: transparent url("/assets/img/login/login-image.png");
    background-position: center center;
    background-size: cover;
    border-width: 1px;
  }

  .login-screen .pos-image-left {
    position: absolute;
    width: 50%;
    height: 100vh;
    left: 0px;
  }



  .login-screen  .pos-full-canvas-right {
    position: absolute;
    width: 50%;
    height: 100vh;
    right: 0px;
  }

  .login-screen .outer-form {
    //background-color: #1b77c5;
    min-height: 489px;
    width: 444px;
  }

  .login-screen .forgot-pw-button {
    margin-right: -16px;
    margin-top: -8px;
  }


  .login-screen .submit-button {
    height: 44px;
  }



  //new reset page



form .login-field.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 24px 0 0 24px;
  border-right-style: none;
}

.login-field.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  border: 1px solid currentColor;
  min-width: 24px;
}

.login-field.mat-form-field-appearance-outline .mat-form-field-outline {

}

.login-field.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 24px 24px 0;

}

.login-field.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 5px 0 11px 0;
}

.login-header-text {


  margin-bottom: 40px;
  text-align: center;

  letter-spacing: 0px;

}
.login-field-label {

  letter-spacing: 0px;
  color: #9EA3AA;
  opacity: 1;
}

.login-field div.mat-form-field-infix input.mat-input-element {

  color: #495462 !important;
  //color:red;
}

.login-left-header {
  background: transparent url("/assets/img/login/login-image.png");
  height: 100vh;
  background-size: cover;
}

@media only screen and (max-width: 700px) {
  div.login-left-header {
    visibility: hidden;
    max-width: 0;
    //flex: 0 0 0;
  }
  div.login-right-header {
    max-width: 100%;
    flex: 0 0 100%;
  }
  html body app-root main.site-main {
    min-width: 400px !important;
  }

  div.login-page div.row {
    flex-wrap: nowrap;
  }
}

.login-outer-pane {

  width: 372px;
}

.login-field {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

}

.login-page .login-options {
  margin-bottom: 20px;
}

.login-btn {
  width: 100%;
  height: 48px;
}

.login-page .login-options .check-box{

  height: 36px;
}

.login-page .login-options .mat-button-wrapper{
}

app-user-name {
  margin-top: 20px;
}
