


table.mat-table {
  background-color: transparent;
}

table.arl-table {
  width: 100%;
}

.arl-table .cell-right {
  text-align: right;

}

.arl-table .name {
  font-size: 18px;
}

.arl-table tr.arl-table-row td {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.arl-table th.mat-header-cell:first-of-type {
  padding-left: 0 !important;
}

.arl-table tr.arl-table-row td:first-child {
  padding-left: 0;
  padding-right: 1rem;
}

.arl-table tr.arl-table-row td:last-child {
  padding-right: 0;
}

.arl-table tr.arl-table-row:hover {
  background: #e5e5e5;
}

.arl-table tr.suspended {
  opacity: 0.5;
}
