.screen-editor-center  .answer-container {
  margin-top: 100px;
}

.screen-editor-center .color-picker-class {
  height: 35px;
  top: 10px
}

.screen-editor-center .pos-button-add-answer {
  position: absolute;
  left: 0px;

}

.screen-editor-center .pos-button-add-answer-text {
  width: 261px;
}

.screen-editor-center .color-picker-class {
  height: 35px;
  top: 10px
}

.screen-editor-center .pos-answer-header {
  display: flex;
  justify-content: space-between;
}

.screen-editor-center .pos-title {
  position: relative;
  margin-bottom: 37px;
}

.screen-editor-center .style-icon {
  position: relative;
  top: 3px;
  transform: scale(0.83);
  cursor: pointer;
}

.screen-editor-center .pos-answer-image-container {
  position: relative;
  height: 152px;
  margin-top: 13px;
}

.screen-editor-center .pos-answer-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 150px;
  height: 152px;
}

.screen-editor-center .pos-answer-no-image {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px dashed #000000;
  border-radius: 4px;
  opacity: 0.12;
}

.screen-editor-center .pos-answer-image-instruction {
  position: absolute;
  left: 150px;
  padding-top: 30px;
  padding-left: 46px;
}

.screen-editor-center .pos-select-image-button {
  position: relative;
  margin-top: 18px;
  text-transform: uppercase;
}

.screen-editor-center .pos-feedback-image-container {
  position: relative;
  height: 238px;
  margin-top: 13px;
}

.screen-editor-center .pos-feedback-image {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 150px;
  height: 100%;
}

.screen-editor-center .pos-icon-image-answer{
  position: relative;
  margin-top: 53px;
  text-align: center;
}

.screen-editor-center .pos-icon-image {
  position: relative;
  margin-top: 80px;
  text-align: center;
}

.screen-editor-center .pos-feedback-image-instruction {
  position: absolute;
  left: 150px;
  padding-top: 64px;
  padding-left: 46px;
}

.screen-editor-center .pos-horizontal-line {
  position: relative;
  width: 100%;
  border: 1px solid #000000;
  opacity: 0.12;
}