app-answers-gallery .answers {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 100%;

}

app-answers-gallery .answers .answer:not(:last-child) {
  margin-right: 1rem;
}

app-answers-gallery .answer {
  padding: 1rem;
  min-width: 210px;
  box-shadow: 0 1px 10px #0000001A;
  border: 2px solid transparent;
  border-radius: 2px;
}

app-answers-gallery .answer.selected {
  position: relative;
  border-color: #3DA1DE;
}

app-answers-gallery .answer .background-pane {
  position: relative;
  width: 100%;
  height: 94px;
  object-fit: cover;
}

app-answers-gallery .selected-icon {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: #3DA1DE;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
}

app-answers-gallery .selected-icon .icon {
  font-size: 18px;
  line-height: 24px;
}

app-answers-gallery .user-list {
  list-style: none;
  padding: 0.5rem 0 0;
  margin: 0;
}

app-answers-gallery .user-list-item {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;
}

app-answers-gallery .user-list-item .user-avatar {
  margin-right: 1rem;
}

app-answers-gallery .user-avatar {
  width: 30px;
  height: 30px;
  background: #DFE4E6 0 0 no-repeat padding-box;
  border-radius: 2px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0;
}

app-answers-gallery .user-avatar mat-icon {
  font-size: 20px;
  line-height: 31px;
  color: #ffffff;
  display: none;
}

app-answers-gallery .user-list-item.selectable .user-name {
  opacity: 0.54;
}

app-answers-gallery .user-list-item.selectable:hover .user-name,
app-answers-gallery .user-list-item.selectable.selected .user-name {
  opacity: 1;
}

app-answers-gallery .user-list-item.selectable:hover .user-avatar,
app-answers-gallery .user-list-item.selectable.selected .user-avatar {
  background: #f44336;
}

app-answers-gallery .user-list-item.selectable:hover .user-avatar .user-avatar__text,
app-answers-gallery .user-list-item.selectable.selected .user-avatar .user-avatar__text {
  display: none;
}

app-answers-gallery .user-list-item.selectable:hover .user-avatar mat-icon,
app-answers-gallery  .user-list-item.selectable.selected .user-avatar mat-icon {
  display: inline-block;
}

app-answers-gallery .user-avatar__text {
  color: #000000;
  opacity: 0.3;
}
